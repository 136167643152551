import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import useMobile from "../hooks/useMobile";
import SlotLink from "./slot-link_old";
import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";
import { motion, AnimatePresence } from "motion/react";
import { Game } from "@hooks/use-slots-list";
export default function CarouselSlider({
  items,
  resultCountNumber
}: {
  items: Game[];
  resultCountNumber: number;
}) {
  const isMobile = useMobile({
    breakPointThree: 600
  });
  const [startIndex, setStartIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const resultCount = useMemo(() => {
    if (isMobile === "mobile-one") return 6;else if (isMobile === "mobile-two") return 5;else if (isMobile === "mobile-three") return 4;else if (isMobile === "mobile-four") return 3;else return resultCountNumber;
  }, [isMobile, resultCountNumber]);
  const maxPages = useMemo(() => {
    const totalItems = items.length;
    const calculatedPages = Math.floor(totalItems / resultCount);
    if (totalItems > resultCount && calculatedPages < 2) {
      return Math.max(calculatedPages, 2);
    }
    return calculatedPages;
  }, [items.length, resultCount]);
  const maxItems = useMemo(() => {
    return resultCount * maxPages;
  }, [resultCount, maxPages]);
  const handleNext = useCallback(() => {
    setDirection(1);
    const newIndex = startIndex + resultCount;
    if (newIndex < maxItems) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(0);
    }
  }, [resultCount, startIndex, maxItems]);
  const handlePrevious = useCallback(() => {
    setDirection(-1);
    const newIndex = startIndex - resultCount;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(Math.max(0, maxItems - resultCount));
    }
  }, [resultCount, startIndex, maxItems]);
  useEffect(() => {
    setStartIndex(0);
  }, [isMobile, items]);
  const isFirstPage = startIndex === 0;
  const isLastPage = startIndex + resultCount >= maxItems;
  const showArrows = maxPages > 1;
  const carouselVariants = {
    hidden: (direction: number) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: "easeInOut"
      }
    },
    exit: (direction: number) => ({
      x: direction > 0 ? "-100%" : "100%",
      opacity: 0,
      transition: {
        duration: 0.3
      }
    })
  };
  return <div className="relative min-h-full" data-sentry-component="CarouselSlider" data-sentry-source-file="carousel-slider.tsx">
      {showArrows && <div className="flex absolute right-0 top-[-2.8rem]">
          <div className={`flex items-center justify-center w-[32px] [@media(min-width:640px)]:w-[49px] h-[32px] rounded-md border border-solid border-[rgba(124,131,177,0.2)] rounded-r-none cursor-pointer border-r-[0.5px] bg-[#292E52] transition-all duration-100 ${isFirstPage ? "opacity-50 cursor-default" : "hover:border-[rgba(124,131,177,0.4)] hover:from-[rgba(124,131,177,0.4)] hover:to-[rgba(94,103,158,0.4)]"}`} onClick={!isFirstPage ? handlePrevious : undefined}>
            <PrevArrowSquareIcon />
          </div>

          <div className={`flex items-center justify-center w-[32px] [@media(min-width:640px)]:w-[49px] h-[32px] rounded-[6px] border border-solid border-[rgba(124,131,177,0.2)] rounded-l-none cursor-pointer border-l-[0.5px] bg-[#292E52] transition-all duration-100 ${isLastPage ? "opacity-50 cursor-default" : "hover:border-[rgba(124,131,177,0.4)] hover:from-[rgba(124,131,177,0.4)] hover:to-[rgba(94,103,158,0.4)]"}`} onClick={!isLastPage ? handleNext : undefined}>
            <NextArrowSquareIcon />
          </div>
        </div>}

      <AnimatePresence mode="popLayout" initial={false} custom={direction} data-sentry-element="AnimatePresence" data-sentry-source-file="carousel-slider.tsx">
        <motion.div key={startIndex} custom={direction} variants={carouselVariants} initial="hidden" animate="visible" exit="exit" className="grid grid-flow-col gap-2.5 auto-cols-[calc(100%_/_var(--cols)_-_10px_*_(var(--cols)-1)/var(--cols))]" style={{
        "--cols": resultCount
      } as CSSProperties} drag="x" dragConstraints={{
        right: 0,
        left: 0
      }} dragElastic={0.2} onDragEnd={(e, {
        offset,
        velocity
      }) => {
        const swipeThreshold = 50;
        const swipePower = Math.abs(offset.x) * velocity.x;
        if (swipePower < -swipeThreshold) {
          handleNext();
        } else if (swipePower > swipeThreshold) {
          handlePrevious();
        }
      }} data-sentry-element="unknown" data-sentry-source-file="carousel-slider.tsx">
          {Array.from({
          length: resultCount
        }).map((_, i) => {
          const item = items[startIndex + i];
          return item ? <SlotLink key={`${item.id}-${startIndex + i}`} item={item} /> : <div key={`empty-${i}`} className="aspect-[0.7562189055]" />;
        })}
        </motion.div>
      </AnimatePresence>
    </div>;
}